.mobile_header {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: white;
  transform: translateX(-100%);
  top: 0;
  transition: all 0.25s ease-in-out;
  z-index: 9999;

  &.open {
    transform: translateX(0);
  }

  .nav {
    .menu_list {
      flex-direction: column;

      .menu_item {
        padding: 1rem 0;

        &:not(:last-child) {
          border-bottom: 1px solid var(--grey-100);
        }
      }
    }
  }

  .menu_item {
    border-bottom: 1px dashed var(--grey-50);

    &:last-child {
      border-bottom: none;
    }

    .label {
      height: 56px;
      font-size: 1rem;
      font-weight: 600;
      color: var(--grey-500);
      display: flex;
      align-items: center;
    }
  }

  .submenu_list {
    border-radius: 8px;
    background: rgba(242, 243, 245, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .submenu_item {
      padding: 0.75rem;
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--grey-800);
      border-bottom: 1px dashed var(--grey-50);

      &:last-child {
        border-bottom: none;
      }

      .icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
      }
    }
  }
}


.gradient {
  background: linear-gradient(313.75deg, #3793FF -2.28%, #182EA3 98.98%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}