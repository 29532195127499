.menu_item {
  font-size: 1rem;
  line-height: 150%;
  transition: color 0.25s ease-in-out;
  color: var(--grey-500);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &.dark_mode {
    color: var(--grey-200);
  }

  &:hover {
    color: var(--primary);
  }

  .submenu {
    position: absolute;
    background-color: white;
    bottom: 0;
    border-radius: 1rem;
    border: 2px solid var(--grey-800);
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
    transform-origin: 50% -30px;
    overflow: hidden;

    .submenu_list {
      .submenu_item {
        padding: 0.75rem;
        white-space: nowrap;
        color: var(--grey-800);
        font-size: 0.875rem;
        font-weight: 600;
        min-width: 200px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: var(--primary-blue-50);
          color: var(--primary);
        }
      }
    }
  }
}
