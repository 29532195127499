.input_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .label {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  .input {
    background: white;
    border: 1px solid var(--grey-100);
    border-radius: 0.375rem;
    height: 2.5rem;
    padding: 0 1rem;
    outline: none;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    transition: all 0.25s ease-in-out;
    width: 100%;

    &.large {
      height: 3rem;
    }

    &:focus {
      border-color: var(--primary);
    }

    &::placeholder {
      color: var(--grey-300);
    }
  }

  .error {
    font-size: 0.75rem;
    color: var(--red);
  }
}
