.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    height: 40px;
    background-color: var(--accent-orange);
}

.dot {
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: var(--primary-blue-50);
}