.feedback {
  padding: 1rem;
  border-radius: 1.25rem;
  background: white;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border: 3px solid var(--grey-800);
  letter-spacing: -0.1px;
  max-width: 384px;
  white-space: pre-line;
  box-shadow: 12px 12px 0px 0px #c8d8ff;

  .divider {
    width: 100%;
    height: 1px;
    background: var(--grey-100);
  }

  .description {
    color: var(--grey-600);
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
