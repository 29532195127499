@import "~/styles/responsive";

.features {
  background-color: var(--grey-900);
  color: white;
  padding: 2.5rem 0;
  overflow: hidden;

  @include devices(lg) {
    padding: 6.25rem 0;
  }

  :global(.section-title) {
    color: white;
  }

  .description {
    font-size: 0.875rem;
    color: var(--grey-200);
    margin-bottom: 2rem;
    text-align: center;
    max-width: 600px;

    @include devices(md) {
      font-size: 1rem;
      margin-bottom: 4rem;
    }
  }

  .card {
    --primary-color: var(--accent-green);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-600);
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    height: 100%;

    &.card_1 {
      --primary-color: var(--accent-green);
    }

    &.card_2 {
      --primary-color: var(--accent-orange);
    }

    &.card_3 {
      --primary-color: var(--accent-blue);
    }

    &.card_4 {
      --primary-color: var(--accent-yellow);
    }

    &.card_5 {
      --primary-color: var(--accent-pink);
    }

    &.card_6 {
      --primary-color: var(--accent-violet);
    }

    .see_demo {
      transition: all 0.25s ease-in-out;

      span:last-child {
        font-weight: 600;
        text-decoration: underline;
      }

      &:hover {
        color: var(--primary-color);
      }
    }

    &:hover {
      background-color: white;

      .card_title {
        color: var(--primary-color);
      }

      .card_description {
        color: var(--grey-600);
      }

      .card_image img {
        transform: scale(1.1);
      }

      .learn_more {
        color: var(--primary);

        .icon {
          transform: rotate(45deg);
        }
      }
    }

    .card_image {
      overflow: hidden;

      img {
        transition: all 0.3s ease-in-out;
      }
    }

    .card_title {
      font-weight: 600;
      font-size: 1.25rem;
      transition: all 0.3s ease-in-out;

      @include devices(md) {
        font-size: 1.5rem;
      }
    }

    .card_description {
      color: var(--grey-200);
      font-size: 0.875rem;
      font-weight: 400;
      transition: all 0.3s ease-in-out;
    }

    .learn_more {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem 0;
      font-size: 1rem;
      font-weight: 600;
      transition: all 0.3s ease-in-out;

      .content {
        text-decoration: underline;
      }

      .icon {
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  .integration {
    border-radius: 20px;
    background: var(--grey-700);
    padding: 1.5rem 1rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include devices(md) {
      padding: 3rem 2.5rem;
      flex-direction: row;
      align-items: center;
    }

    .integration_title {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5;
      color: white;
      max-width: 500px;

      @include devices(lg) {
        font-size: 2rem;
      }
    }

    .integration_desc {
      font-size: 0.875rem;
      line-height: 1.5;
      color: var(--grey-200);

      @include devices(lg) {
        font-size: 1rem;
      }
    }
  }
}
