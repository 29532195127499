@import "~/styles/responsive.scss";

.header_container {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;

  .header_content_container {
    max-width: 1440px;
    padding: 0 1rem;
    margin: 0 auto;

    @include devices(md) {
      padding: 0 1.5rem;
    }
  }
}

.header {
  padding: 1rem 0;
  transition: background-color 0.25s ease-in-out;
  top: 3rem;
  background-color: white;

  &.dark_mode {
    background-color: var(--grey-900);

    .nav {
      .menu_list {
        .menu_item {
          color: var(--grey-100);
        }
      }
    }
  }

  .header_logo {
    position: relative;
    width: 100px;
    aspect-ratio: calc(100 / 24);

    @include devices(md) {
      width: 150px;
    }
  }

  .nav {
    flex: 1;

    .menu_list {
      display: flex;
      height: 2.5rem;

      @include devices(lg) {
        gap: 20px;
      }

      .solutions {
        width: 841px;
        --primary-color: var(--accent-green);
        --hover-bg-color: rgba(59, 165, 92, 0.1);

        .item_2 {
          --primary-color: var(--accent-violet);
          --hover-bg-color: rgba(112, 45, 255, 0.1);
        }

        .item_3 {
          --primary-color: var(--accent-orange);
          --hover-bg-color: rgba(252, 139, 35, 0.1);
        }

        .item_4 {
          --primary-color: var(--accent-pink);
          --hover-bg-color: rgba(255, 115, 182, 0.1);
        }

        .item_5 {
          --primary-color: var(--accent-blue);
          --hover-bg-color: rgba(62, 112, 221, 0.1);
        }

        .item_6 {
          --primary-color: var(--accent-yellow);
          --hover-bg-color: rgba(255, 177, 48, 0.1);
        }

        .submenu_item {
          width: 100%;
          padding: 1.25rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          display: flex;
          gap: 1rem;
          border-bottom: 1px dashed var(--grey-50);
          border-right: 1px dashed var(--grey-50);

          &:hover {
            background-color: var(--hover-bg-color);
          }

          .icon {
            width: 28px;
            height: 28px;
            background-color: var(--primary-color);
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .submenu_item_title {
            font-size: 1rem;
            font-weight: 600;
            color: var(--grey-800);
          }

          .submenu_item_desc {
            margin-top: 4px;
            font-size: 0.875rem;
            color: var(--grey-500);
          }
        }
      }
    }
  }
}

.hamburger {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include devices(lg) {
    display: none;
  }

  &.active {
    .line {
      transition: all 0.5s;

      &:nth-child(1) {
        top: 2px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: -2px;
        transform: rotate(-45deg);
      }
    }
  }

  .line {
    display: block;
    position: relative;
    width: 50%;
    height: 2px;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.5s;

    &:nth-child(1) {
      top: -4px;
    }

    &:nth-child(3) {
      top: 4px;
    }
  }
}


.gradient {
  background: linear-gradient(313.75deg, #3793FF -2.28%, #182EA3 98.98%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  line-height: 150%;
  transition: color 0.25s ease-in-out;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &.dark_mode {
    color: var(--grey-200);
  }
}