.scroll_container {
  // overflow: hidden;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.scroll_track {
  display: flex;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  gap: 24px;
  width: max-content;
  height: max-content;
  height: 100%;
}

.image {
  min-height: 447px;
  display: block;
}
