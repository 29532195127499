@import "~/styles/responsive.scss";

.discord_banner {
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0.5rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
  backdrop-filter: blur(50px);
  font-size: 0.75rem;

  @include devices(md) {
    font-size: 0.875rem;
    padding: 0.75rem 1.5rem;
  }

  strong {
    font-weight: 600;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: white;
  }

  &.unlock_documenttation {
    background-color: #5865f2;
  }

  &.free_add_on {
    background: linear-gradient(90deg, #5865f2 0%, #0d0a19 100%);
  }

  &.need_help {
    background: var(--grey-900);

    &::after {
      content: "";
      background: radial-gradient(
        50% 50% at 50% 50%,
        #5865f2 0%,
        rgba(23, 24, 26, 0) 100%
      );
      filter: blur(50px);
      border-radius: 100%;
      width: 120%;
      aspect-ratio: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      opacity: 0.8;
    }
  }

  &.chat_with_developers {
    background: var(--grey-800);
  }

  &.report_bug {
    background: linear-gradient(90deg, #5865f2 0%, #01c5ad 100%);
  }

  &.join_conversation {
    background: var(--grey-800);
    background-image: radial-gradient(
        circle at left center,
        #5865f2,
        rgba(23, 24, 26, 0) 50%
      ),
      radial-gradient(circle at right center, #702dff, rgba(23, 24, 26, 0) 50%);
    background-size: cover;
    background-position: center;
  }

  &.stay_in_loop {
    background: var(--accent-green);
  }
}
