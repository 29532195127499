@import "~/styles/responsive.scss";

.key_features {
  position: relative;
  z-index: 1;
  background-color: white;
  padding: 2rem 0 52px;
  overflow: hidden;

  @include devices(lg) {
    padding: 6.25rem 0;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary);
    text-align: center;
    z-index: 1;
    line-height: 1.5;

    @include devices(md) {
      font-size: 2rem;
      line-height: normal;
    }
  }

  .sub_title {
    font-size: 1rem;
    color: var(--grey-900);
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
    z-index: 1;

    @include devices(md) {
      font-size: 1.25rem;
      line-height: normal;
    }
  }

  .logo {
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    max-width: 140px;

    @include devices(md) {
      max-width: 180px;
      margin-bottom: 5rem;
    }

    .background {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 7.5%);
      width: 100%;
      height: 34.5%;
      pointer-events: none;
      object-fit: cover;

      @include devices(md) {
        height: 110%;
        object-fit: contain;
        transform: translate(-50%, -21.5%);
      }

      @include devices(lg) {
        height: 110%;
        object-fit: contain;
        transform: translate(-50%, -18.5%);
      }
    }
  }

  .list {
    display: grid;
    gap: 1.25rem;

    @include devices(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include devices(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .feature_item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    padding: 1.25rem;
    border-radius: 1.5rem;
    border: 1px solid var(--grey-100);
    transition: all 0.25s ease-in-out;
    cursor: default;
    outline: 2px solid transparent;
    overflow: hidden;
    background-color: white;

    .blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 25%;
      background-color: var(--primary-color);
      filter: blur(75px);
      opacity: 0;
      transition: all 0.25s ease-in-out;
    }

    &.feature_1 {
      --primary-color: var(--accent-green);
    }

    &.feature_2 {
      --primary-color: var(--red);
    }

    &.feature_3 {
      --primary-color: var(--orange);
    }

    &.feature_4 {
      --primary-color: var(--accent-blue);
    }

    &:hover {
      border-color: var(--grey-800);
      box-shadow: 0px 0.5rem 0px 0px var(--primary-color);
      outline-color: var(--grey-800);

      .name {
        color: var(--primary-color);
      }

      .blur {
        opacity: 1;
      }
    }

    .name {
      font-size: 1rem;
      font-weight: 600;
      color: var(--grey-800);
      line-height: 1.5;
      transition: color 0.25s ease-in-out;

      @include devices(md) {
        font-size: 1.125rem;
      }
    }

    .description {
      font-size: 0.875rem;
      color: var(--grey-500);
      line-height: 1.5;

      strong {
        color: var(--primary-color);
      }
    }
  }
}
