@import "~/styles/responsive.scss";
@import "~/styles/typed";

.hero {
  padding-top: 72px;
  padding-bottom: 56px;
  overflow: hidden;
  background: linear-gradient(180deg, #ffffff 0%, #f9fafd 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("/svgs/dot.svg");
    z-index: -2;
  }

  .title {
    font-weight: 700;
    font-size: 1.75rem;
    color: var(--grey-900);
    line-height: 1.3;
    text-align: center;
    max-width: 998px;

    @include devices(md) {
      font-size: 2.25rem;
    }
  }

  .subtitle {
    line-height: 1.5;
    font-size: 0.875rem;
    text-align: center;
    display: block;
    margin-top: 0.75rem;
    color: var(--grey-600);

    @include devices(md) {
      font-size: 1rem;
    }
  }

  .cta {
    display: flex;
    gap: 0.5rem;
    margin-top: 2rem;
    justify-content: center;

    button {
      padding: 0 1.25rem;
    }

    @include devices(md) {
      font-size: 2.5rem;
    }
  }
}
