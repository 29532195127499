.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  background: var(--grey-800);
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.08);
  width: 100vw;
  z-index: 10000;

  * {
    line-height: 150%;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   background: url("/images/cookie.png");
  //   background-size: cover;
  //   left: 0;
  //   height: 100%;
  //   aspect-ratio: calc(453 / 366);
  //   z-index: -1;
  //   opacity: 0.5;
  // }

  .link {
    color: var(--accent-orange);
    text-decoration: underline;
  }
}
