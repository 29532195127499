@import "~/styles/responsive.scss";

.customer_feedback {
  position: relative;
  background: var(--primary-blue-50);
  padding: 3rem 0;
  overflow: hidden;

  @include devices(md) {
    padding: 100px 0;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;

    @include devices(md) {
      font-size: 2rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include devices(md) {
      gap: 3rem;
    }

    .feedbacks {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include devices(md) {
        display: grid;
        grid-template-areas:
          "f1 f2 f3"
          "f1 f2 f6"
          "f1 f5 f6"
          "f4 f5 f6"
          "f7 f5 f6"
          "f7 f5 f8"
          "f7 f5 f10"
          "f7 f9 f10";
      }
    }
  }

  .blur {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 296px;
    background: linear-gradient(
      180deg,
      rgba(230, 236, 251, 0) 0%,
      var(--primary-blue-50) 85%
    );
    display: none;

    @include devices(md) {
      display: block;
    }
  }
}
