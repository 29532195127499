@import "~/styles/responsive.scss";

.container {
  padding: 0 1rem;
  max-width: 1240px;
  margin: auto;

  @include devices(md) {
    padding: 0 2rem;
  }
}
