@import "~/styles/responsive.scss";

.integrations {
  background-color: var(--primary);
  overflow: hidden;

  .wrapper {
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    height: 100%;
    z-index: 0;
    padding: 0 1rem;
    // padding: 4.5rem 0 0 0;

    .line {
      position: absolute;
      left: 2%;
      height: 100%;
      width: 0.75rem;

      @include devices(md) {
        left: 5.5%;
      }

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-color: var(--primary-300);
        opacity: 0.5;
      }

      &::after {
        position: absolute;
        content: "";
        background-color: var(--accent);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        border: 3px solid var(--grey-800);
        z-index: 2;

        @include devices(md) {
          top: 43%;
        }

        @include devices(lg) {
          top: 42.5%;
        }
      }
    }

    .line_2 {
      position: absolute;
      border-top: 3px dashed var(--primary-300);
      top: 31.5%;
      left: 5%;
      width: 50%;
      z-index: -1;
      display: none;

      @include devices(md) {
        width: 78%;
        top: 45%;
        left: 6.5%;
        display: block;
      }

      @include devices(xl) {
        width: 75%;
        top: 45%;
        left: 6.5%;
      }
    }

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      text-align: center;
      padding-top: 3rem;
      display: block;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 324px;
      margin: 0 auto;

      @include devices(md) {
        padding-top: 4.5rem;
        font-size: 2rem;
        max-width: unset;
        margin: unset;
      }
    }

    .description {
      color: var(--grey-100);
      margin-top: 0.75rem;
      margin-bottom: 2.25rem;
      font-size: 0.875rem;
      text-align: center;
      padding: 0 1.5rem;

      @include devices(md) {
        font-size: 1rem;
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;
      padding-bottom: 3.125rem;
      padding-left: 1.5rem;

      @include devices(md) {
        flex-direction: row;
        padding-left: 0;
      }
    }

    .button {
      // border: 3px solid var(--grey-800);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 600;
      color: white;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      position: relative;
      font-size: 1.125rem;
      z-index: 2;

      @include devices(md) {
        width: 56px;
        height: 56px;
      }

      &::after {
        position: absolute;
        content: "";
        z-index: -1;
        border: 3px solid var(--grey-800);
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      &::before {
        position: absolute;
        content: "";
        left: 60%;
        top: -28px;
        border-left: 3px dashed var(--primary-300);
        height: 100px;
        transform: rotate(90deg);
        z-index: -2;

        @include devices(md) {
          left: 50%;
          top: calc(100% + 3px);
          border-left: 3px dashed var(--primary-300);
          height: 100px;
          transform: rotate(0);
        }
      }

      &.sdk {
        &::after {
          background-color: var(--accent-blue);
        }
      }

      &.react_native {
        &::after {
          background-color: var(--accent-violet);
        }
      }

      &.flutter {
        &::after {
          background-color: var(--orange);
        }
      }
    }
  }
}

.content_box {
  border-radius: 16px;
  border: 3px solid var(--Neutral-Grey-800, #202225);
  background: var(--Neutral-Grey-00, #fff);
  box-shadow: 8px 9px 0px 0px rgba(0, 0, 0, 0.1);
  max-width: 336px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  z-index: 2;
  gap: 1rem;
  padding: 12px;
  flex: 1;

  @include devices(md) {
    padding: 16px;
  }

  @include devices(xl) {
    padding: 24px;
  }
}
